// import { AnalyticsApiService } from '@analytics/service/analytics-api.service';
import { Dropdown } from 'primeng/dropdown';
import { ApiService } from '../../service/api.service';
import { ApiServiceEss } from '../../service/api.service-ess';

import {
    Input,
    OnInit,
    Output,
    Component,
    OnChanges,
    EventEmitter,
    ChangeDetectorRef,
    ViewChild,
    SimpleChanges,
    AfterViewInit,
} from '@angular/core';
import { MultiSelect } from 'primeng/multiselect';
import { AnalyticsApiService } from '@analytics/service/analytics-api.service';
import { HelperService } from '@core/service/helper-service';

@Component({
    selector: 'app-lov',
    styleUrls: ['./lov.component.scss'],
    templateUrl: './lov.component.html',
})
export class LovComponent implements OnInit, OnChanges {
    @ViewChild('multiLovs') public multiLovs: MultiSelect;
    @ViewChild('lov') public lov: Dropdown;

    @Input() public readonly id: string = 'id';
    @Input() public readonly useChip: boolean = false;
    @Input() public readonly isTaxObject: boolean = false;
    @Input() public readonly isPeriodCW: boolean = false;
    @Input() public readonly isPeriodCWDetailed: boolean = false;
    @Input() public readonly truncate: number = 55;
    @Input() public readonly label?: string;
    @Input() public readonly haveNullValue: boolean = false;
    @Input() public readonly isMulti: boolean = false;
    @Input() public readonly showToggleAll: boolean = true;
    @Input() public readonly readonly: boolean = false;
    @Input() public readonly disabled: boolean = false;
    @Input() public readonly filter: boolean = true;
    @Input() public readonly ngClass?: any;
    @Input() public readonly styleClass: string = '';
    @Input() public readonly optLabel: string = 'name';
    @Input() public readonly showClear: boolean = false;
    @Input() public readonly appendTo: string = 'body';
    @Input() public readonly selectAll: boolean = false;
    @Input() protected value?: string;
    @Input() protected values: any[] = [];
    @Input() protected readonly chaining: boolean = false;
    @Input() protected readonly uri?: string;
    @Input() protected readonly valuesFind: string = 'id';
    @Input() protected readonly fetchOnInit: boolean = true;
    @Input() protected readonly addedAll: boolean = false;
    @Input() protected readonly labelAll: string = 'All';
    @Input() protected readonly getFirstValue: boolean = false;
    @Input() protected readonly getCustomValue: boolean = false;
    @Input() protected readonly getLastIndexValue?: number;
    @Input() protected readonly needEmitWhenFound: boolean = false;
    @Input() protected readonly defaultValue?: { field: string; value: string };
    @Input() protected readonly isValueLabel: boolean = false;
    @Input() protected readonly customOption: any[] = [];
    @Input() protected readonly IsCustomValueId: boolean = false;
    @Input() protected readonly web: string | null =
        localStorage.getItem('web');
    @Input() protected readonly isAnalytics: boolean = false;
    @Input() protected readonly prefixFrontLabel?: string;
    @Input() protected readonly isBoldValueLabelPrefix?: boolean;

    @Input() protected readonly customOptsSelectedWorksheet:
        | boolean
        | undefined = false;

    public selectedData?: any;
    public list: any[] = [];
    public loading: boolean = true;
    public selectedDatas: any[] = [];

    @Output() public selected = new EventEmitter<any>();
    @Output() public hide = new EventEmitter<any>();
    @Output() public clear = new EventEmitter<any>();
    @Output() public nullAlert = new EventEmitter<any>();
    @Output() public setupKeys = new EventEmitter<any>();

    protected srv: any;

    constructor(
        private readonly ref: ChangeDetectorRef,
        private readonly srv1: ApiService,
        private readonly srv2: ApiServiceEss,
        private readonly srv3: AnalyticsApiService,
        private readonly _helperSrv: HelperService
    ) {}

    public ngOnInit(): void {
        this.initializeService();
        this.selectedData = null;
        this.selectedDatas = [];
        if (this.fetchOnInit) {
            this.fetchData();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.uri && !changes.uri.firstChange) {
            this.fetchData();
        }

        if (changes.customOption && !changes.customOption.firstChange) {
            this.fetchData();
        }

        if (this.chaining) {
            this.updateSelectedData();
        } else {
            this.setSelectedData();
        }
    }

    private initializeService(): void {
        this.srv = this.isAnalytics
            ? this.srv3
            : this.web === 'admin'
            ? this.srv1
            : this.srv2;
    }

    private fetchData(): void {
        if (this.uri && !this.uri.includes('undefined')) {
            this.list = [];
            this.loading = true;

            this.srv.get(this.uri).subscribe({
                next: (res) => {
                    this.list = res.data || [];
                    this.loading = false;

                    if (this.list.length === 0) {
                        this.nullAlert.emit(null);
                    } else {
                        this.handleFetchData();
                        if (this.chaining) {
                            this.updateSelectedData();
                        }
                        this.setupKeys.emit(Object.keys(this.list[0]));
                    }
                },
                error: (err) => {
                    this.loading = false;
                },
            });
        } else if (this.customOption.length > 0) {
            this.handleCustomOptions();
        } else {
            this.list = [];
            this.loading = false;
        }
    }

    private handleCustomOptions(): void {
        this.customOption.forEach((item) => {
            item.value = item.value || item.name;
            item.id = item.id || item.key || item.code;
            item.name = item.name || item.value;
        });

        this.list = this.customOption;
        this.loading = false;
        this.handleFetchData();
        if (this.chaining) {
            this.updateSelectedData();
        }
    }

    private handleFetchData(): void {
        if (this.haveNullValue) {
            this.list = [{ id: '', [this.optLabel]: '-' }].concat(this.list);
        }

        if (this.addedAll) {
            this.list = [{ id: 'all', [this.optLabel]: this.labelAll }].concat(
                this.list
            );
        }

        if (this.getFirstValue) {
            this.selectedData = this.list[0];
            if (this.isMulti) {
                this.selectedDatas.push(this.selectedData);
                this.onChange(this.selectedDatas);
            } else {
                this.onChange(this.selectedData);
            }
        }

        if (this.getLastIndexValue) {
            this.selectedData =
                this.list[this.list.length - (1 + this.getLastIndexValue)];
            if (this.isMulti) {
                this.selectedDatas.push(this.selectedData);
                this.onChange(this.selectedDatas);
            } else {
                this.onChange(this.selectedData);
            }
        }

        if (this.getCustomValue) {
            this.selectedData = this.list.find(
                (item) =>
                    item[this.defaultValue?.field] === this.defaultValue?.value
            );
            if (this.isMulti) {
                this.selectedDatas.push(this.selectedData);
                this.onChange(this.selectedDatas);
            } else {
                this.onChange(this.selectedData);
            }
        }

        if (!this.chaining) {
            this.setSelectedData();
        }
    }

    private setSelectedData(): void {
        if (this.list.length > 0 && !this.selectedDatas.length) {
            this.selectedDatas = this.values
                ?.map((element) => {
                    const valueKey = this.IsCustomValueId
                        ? this.valuesFind
                        : 'id';
                    const elementKey = this.IsCustomValueId
                        ? element
                        : element[this.valuesFind];
                    return this.list.find(
                        (data) => data[valueKey] === elementKey
                    );
                })
                .filter((foundData) => foundData);

            if (this.selectedDatas.length) {
                this.selected.emit(this.selectedDatas);
            }
        }

        if (!this.selectedData && this.list.length > 0) {
            if (this.isValueLabel) {
                this.selectedData = this.list.find(
                    (data) => data?.[this.optLabel] === this.value
                );
            } else if (this.IsCustomValueId) {
                this.selectedData = this.list.find(
                    (data) => data[this.valuesFind] === this.value
                );
            } else {
                this.selectedData = this.list.find(
                    (data) => data.id === this.value
                );
            }

            if (this.selectedData) {
                this.selected.emit(this.selectedData);
            }
        }

        this.lov?.cd.detectChanges();
        this.multiLovs?.cd.detectChanges();
        this.ref.detectChanges();
    }

    private updateSelectedData(): void {
        if (this.value && this.list.length > 0) {
            if (this.isValueLabel) {
                this.selectedData = this.list.find(
                    (data) => data?.[this.optLabel] === this.value
                );
            } else if (this.IsCustomValueId) {
                this.selectedData = this.list.find(
                    (data) => data[this.valuesFind] === this.value
                );
            } else {
                this.selectedData = this.list.find(
                    (data) => data.id === this.value
                );
            }

            if (this.selectedData) {
                this.selected.emit(this.selectedData);
            }
        }

        if (
            Array.isArray(this.values) &&
            this.values?.length > 0 &&
            this.list.length > 0
        ) {
            this.selectedDatas = this.values
                ?.map((element) => {
                    const valueKey = this.IsCustomValueId
                        ? this.valuesFind
                        : 'id';
                    const elementKey = this.IsCustomValueId
                        ? element
                        : element[this.valuesFind];
                    return this.list.find(
                        (data) => data[valueKey] === elementKey
                    );
                })
                .filter((foundData) => foundData);

            if (this.selectedDatas.length) {
                this.selected.emit(this.selectedDatas);
            }
        }

        this.lov?.cd.detectChanges();
        this.multiLovs?.cd.detectChanges();
        this.ref.detectChanges();
    }

    public onChange(selectedData: any): void {
        if (this.isMulti && Array.isArray(selectedData)) {
            this.selectedDatas = selectedData;
        } else {
            this.selectedData = selectedData;
        }

        this.selected.emit(selectedData);
    }

    public onHide(): void {
        this.hide.emit();
    }

    public onClear(e): void {
        this.clear.emit();
    }

    public reset(): void {
        this.selectedData = null;
        this.selectedDatas = [];
    }

    public contentPeriodDetail(data: any): string {
        return ` - ${data?.lovPeriodTypeName.replace(/ly$/, '')} ${
            data?.sequence
        } (${this._helperSrv.formatDate(
            new Date(data?.periodStart),
            null,
            'dd/MM/yyyy'
        )} - ${this._helperSrv.formatDate(
            new Date(data?.periodEnd),
            null,
            'dd/MM/yyyy'
        )})`;
    }

    public removeSelectedDatas(item): void {
        const idx = this.selectedDatas.findIndex((find) => find === item);
        if (idx !== -1) {
            this.selectedDatas.splice(idx, 1);
            this.multiLovs.cd.detectChanges();
            this.ref.detectChanges();
        }
    }

    public setValueAll(): void {
        if (this.addedAll) {
            this.selectedData = null;
            this.selectedData = [];

            this.selectedData = this.list[0];
            if (this.isMulti) {
                this.selectedDatas.push(this.selectedData);
            }
        }
    }
}
