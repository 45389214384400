import { Router } from '@angular/router';
import { AppConstant } from '@core/config/app.config';
import { AppMainEssComponent } from './app.main.component';
import { AuthenticationService } from 'src/app/core/service/authentication.service';

import { OnInit, Component } from '@angular/core';

@Component({
    selector: 'app-menu-ess',
    template: `
        <div class="layout-menu-container mt-4">
            <p
                style="color:var(--secondary-grey);font-size:14px;font-weight:600;padding: 10px 16px 6px 16px; margin: 0 !important"
            >
                MENU
            </p>
            <ul
                class="layout-menu-ess"
                role="menu"
                (keydown)="onKeydown($event)"
            >
                <li
                    app-menu-ess
                    class="layout-menuitem-category"
                    *ngFor="let item of model; let i = index"
                    [item]="item"
                    [index]="i"
                    [root]="true"
                    role="none"
                >
                    <div
                        class="layout-menuitem-root-text"
                        [attr.aria-label]="item.label"
                    >
                        {{ item.label }}
                    </div>
                    <ul role="menu">
                        <li
                            app-menuitem-ess
                            *ngFor="let child of item.items"
                            [parent]="item.label"
                            [item]="child"
                            [index]="i"
                            role="none"
                        ></li>
                    </ul>
                </li>
            </ul>
        </div>
    `,
})
export class AppMenuEssComponent implements OnInit {
    model: any[];
    web = localStorage.getItem('web');
    menuEss = [
        {
            items: [
                {
                    label: 'Employee Administration',
                    icon: 'fa-solid fa-id-badge',
                    alias: 'employee-administration',
                    items: [
                        {
                            label: 'Asset Management',
                            styleClass: '',
                            alias: 'asset-management',
                            items: [
                                {
                                    label: 'Asset Request',
                                    styleClass: '',
                                    routerLink: [
                                        '/ess/employee-administration/asset-management/asset-request',
                                    ],
                                },
                                {
                                    label: 'Asset Assignment',
                                    styleClass: '',
                                    routerLink: [
                                        '/ess/employee-administration/asset-management/asset-assignment',
                                    ],
                                },
                                {
                                    label: 'Asset Return',
                                    styleClass: '',
                                    routerLink: [
                                        '/ess/employee-administration/asset-management/asset-return',
                                    ],
                                },
                                {
                                    label: 'Asset PIC Confirmation',
                                    styleClass: '',
                                    routerLink: [
                                        '/ess/employee-administration/asset-management/asset-pic-confirmation',
                                    ],
                                },
                                {
                                    label: 'Asset History',
                                    styleClass: '',
                                    routerLink: [
                                        '/ess/employee-administration/asset-management/history-request',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Document Management',
                            styleClass: '',
                            alias: 'document-management',
                            items: [
                                {
                                    label: 'Document Assignment',
                                    styleClass: '',
                                    routerLink: [
                                        '/ess/employee-administration/document-management/document-assignment',
                                    ],
                                },
                                {
                                    label: 'Document Return',
                                    styleClass: '',
                                    routerLink: [
                                        '/ess/employee-administration/document-management/document-return',
                                    ],
                                },
                                {
                                    label: 'Document PIC Confirmation',
                                    styleClass: '',
                                    routerLink: [
                                        '/ess/employee-administration/document-management/document-pic-confirmation',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Resignation',
                            styleClass: '',
                            alias: 'resignation',
                            items: [
                                {
                                    label: 'Resignation Request',
                                    styleClass: '',
                                    routerLink: [
                                        '/ess/employee-administration/resignation/request',
                                    ],
                                },
                                {
                                    label: 'Exit Interview',
                                    styleClass: '',
                                    routerLink: [
                                        '/ess/employee-administration/resignation/exit-interview',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Letter Request',
                            styleClass: '',
                            alias: 'letter-request',
                            items: [
                                {
                                    label: 'Letter Request',
                                    styleClass: '',
                                    routerLink: [
                                        '/ess/employee-administration/letter-request/letter-request',
                                    ],
                                },
                                {
                                    label: 'Letter Request History',
                                    styleClass: '',
                                    routerLink: [
                                        '/ess/employee-administration/letter-request/letter-request-history',
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    label: 'Time',
                    icon: 'fas fa-clock',
                    alias: 'time',
                    items: [
                        {
                            label: 'Attendance',
                            styleClass: '',
                            routerLink: ['/ess/time/attendance/attendance'],
                        },
                        {
                            label: 'My Timesheet',
                            styleClass: '',
                            routerLink: ['/ess/time/attendance/my-timesheet'],
                        },
                        {
                            label: 'My Calendar',
                            styleClass: '',
                            alias: 'myCalendar',
                            routerLink: ['/ess/time/my-calendar'],
                        },
                        {
                            label: 'Overtime',
                            styleClass: '',
                            alias: 'overtime-plan',
                            items: [
                                {
                                    label: 'Overtime Plan Confirmation',
                                    styleClass: '',
                                    routerLink: [
                                        '/ess/time/overtime-plan/overtime-plan-confirmation',
                                    ],
                                },
                                {
                                    label: 'Overtime Report',
                                    styleClass: '',
                                    routerLink: [
                                        '/ess/time/overtime-plan/overtime-report',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Leave Request',
                            styleClass: '',
                            routerLink: ['/ess/time/leave-request/list'],
                        },
                        {
                            label: 'Permit Request',
                            styleClass: '',
                            routerLink: ['/ess/time/permit-request/list'],
                        },
                        {
                            label: 'Request History',
                            styleClass: '',
                            alias: 'request-history',
                            routerLink: '/ess/time/request-history',
                        },
                        {
                            label: 'Switch Schedule',
                            styleClass: '',
                            alias: 'switch-schedule',
                            items: [
                                {
                                    label: 'Personal Switch Schedule Request',
                                    styleClass: '',
                                    routerLink: [
                                        '/ess/time/switch-schedule/personal-switch-schedule',
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    label: 'Reimbursement',
                    icon: 'fa-solid fa-hand-holding-dollar',
                    alias: 'reimbursement-ess',
                    items: [
                        {
                            label: 'Medical',
                            styleClass: '',
                            alias: 'medical',
                            items: [
                                {
                                    label: 'Medical Limit',
                                    styleClass: '',
                                    routerLink: [
                                        '/ess/reimbursement-ess/medical/medical-request/limit',
                                    ],
                                },
                                {
                                    label: 'Medical Request',
                                    styleClass: '',
                                    routerLink: [
                                        '/ess/reimbursement-ess/medical/medical-request/claim',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Business Trip Assignment',
                            styleClass: '',
                            routerLink: [
                                '/ess/reimbursement-ess/business-trip/business-trip-assignment',
                            ],
                        },
                        {
                            label: 'Donation Request',
                            styleClass: '',
                            routerLink: [
                                '/ess/reimbursement-ess/donation-request',
                            ],
                        },
                    ],
                },
                {
                    label: 'Payroll',
                    icon: 'fa-solid fa-wallet',
                    alias: 'payroll-ess',
                    items: [
                        {
                            label: 'Payslip',
                            styleClass: '',
                            alias: 'payslip',
                            routerLink: ['/ess/payroll-ess/payslip/index'],
                        },
                        // {
                        //     label: 'SPT 1721 A1',
                        //     styleClass: '',
                        //     alias: 'spt',
                        //     routerLink: ['/ess/payroll-ess/spt/index'],
                        // },
                        {
                            label: 'Loan',
                            styleClass: '',
                            alias: 'loan',
                            items: [
                                {
                                    label: 'Display Loan Installment by Payroll',
                                    styleClass: '',
                                    alias: 'installment-by-payroll',
                                    routerLink: [
                                        '/ess/payroll-ess/loan/installment-by-payroll/index',
                                    ],
                                },
                                // {
                                //     label: 'Display Loan Installment by Cash',
                                //     styleClass: '',
                                //     alias: 'installment-by-cash',
                                //     routerLink: [
                                //         '/ess/payroll-ess/loan/installment-by-cash/index',
                                //     ],
                                // },
                                {
                                    label: 'Loan Request',
                                    styleClass: '',
                                    alias: 'loan-request',
                                    routerLink: [
                                        '/ess/payroll-ess/loan/loan-request/index',
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    label: 'Learning',
                    icon: 'fa-solid fa-users',
                    alias: 'training',
                    items: [
                        {
                            label: 'Learning Test Request',
                            styleClass: '',
                            alias: 'learning-test-request',
                            routerLink: [
                                '/ess/training/approval-learning-test-request/list',
                            ],
                        },
                        {
                            label: 'Learning Test Confirmation',
                            styleClass: '',
                            alias: 'learning-test-confirmation',
                            routerLink: [
                                '/ess/training/approval-learning-test-confirmation/list',
                            ],
                        },
                        {
                            label: 'Learning Assignment',
                            styleClass: '',
                            alias: 'learning-assignment',
                            routerLink: [
                                '/ess/training/activities-learning-assignment/list',
                            ],
                        },
                    ],
                },
                {
                    label: 'Competency',
                    icon: 'fas fa-clipboard-list',
                    alias: 'competency-ess',
                    items: [
                        {
                            label: 'My Competency',
                            icon: '',
                            styleClass: '',
                            routerLink: ['/ess/competency-ess/my-competency'],
                        },
                        {
                            label: 'Competency Review',
                            icon: '',
                            styleClass: '',
                            routerLink: [
                                '/ess/competency-ess/competency-review',
                            ],
                        },
                        {
                            label: 'Individual Result',
                            icon: '',
                            styleClass: '',
                            routerLink: [
                                '/ess/competency-ess/individual-result',
                            ],
                        },
                    ],
                },
                {
                    label: 'Performance',
                    icon: 'fa-solid fa-chart-simple',
                    alias: 'performance-ess',
                    items: [
                        {
                            label: 'Competency Review',
                            styleClass: '',
                            alias: 'competency-review',
                            routerLink: [
                                '/ess/performance-ess/competency-review/list',
                            ],
                        },
                        {
                            label: 'Individual Result',
                            styleClass: '',
                            alias: 'individual-result',
                            items: [
                                {
                                    label: 'Competency',
                                    styleClass: '',
                                    alias: 'competency',
                                    routerLink: [
                                        '/ess/performance-ess/individual-result/competency/index',
                                    ],
                                },
                                {
                                    label: 'Indicator',
                                    styleClass: '',
                                    alias: 'indicator',
                                    routerLink: [
                                        '/ess/performance-ess/individual-result/indicator/index',
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
    menuMss = [
        {
            items: [
                {
                    label: 'Organization',
                    icon: 'fa-solid fa-sitemap',
                    alias: 'organization',
                    items: [
                        {
                            label: 'Reporting Chart',
                            styleClass: '',
                            routerLink: [
                                '/mss/organization/reporting-chart/list',
                            ],
                        },
                        {
                            label: 'Approval',
                            styleClass: '',
                            alias: 'approval',
                            routerLink: ['/mss/organization/approval/list'],
                        },
                    ],
                },
                {
                    label: 'Employee Administration',
                    icon: 'fa-solid fa-id-badge',
                    alias: 'employee-administration',
                    items: [
                        {
                            label: 'Asset Management',
                            styleClass: '',
                            alias: 'asset-management',
                            items: [
                                {
                                    label: 'Subordinate Asset Request',
                                    styleClass: '',
                                    routerLink: [
                                        '/mss/employee-administration/asset-management/subordinate-asset-request',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Reward Request',
                            styleClass: '',
                            alias: 'reward request',
                            routerLink: [
                                '/mss/employee-administration/reward-request',
                            ],
                        },
                        {
                            label: 'Punishment Request',
                            styleClass: '',
                            alias: 'punishment-request',
                            routerLink: [
                                '/mss/employee-administration/punishment-request/list',
                            ],
                        },
                        {
                            label: 'Approval',
                            styleClass: '',
                            alias: 'approval',
                            routerLink: [
                                '/mss/employee-administration/approval/list',
                            ],
                        },
                    ],
                },
                {
                    label: 'Time',
                    icon: 'fas fa-clock',
                    alias: 'time',
                    items: [
                        {
                            label: 'Attendance',
                            styleClass: '',
                            alias: 'attendance-correction',
                            items: [
                                {
                                    label: 'Attendance Correction',
                                    styleClass: '',
                                    routerLink: [
                                        '/mss/time/attendance-correction-approval/list',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Permit',
                            styleClass: '',
                            alias: 'permit-request',
                            items: [
                                {
                                    label: 'Permit Request',
                                    styleClass: '',
                                    routerLink: [
                                        '/mss/time/permit-request-approval/list',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Leave',
                            styleClass: '',
                            alias: 'leave-request',
                            items: [
                                {
                                    label: 'Leave Request',
                                    styleClass: '',
                                    routerLink: [
                                        '/mss/time/leave-request-approval/list',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Overtime',
                            styleClass: '',
                            alias: 'overtime',
                            items: [
                                {
                                    label: 'Overtime Plan',
                                    styleClass: '',
                                    routerLink: [
                                        '/mss/time/overtime/overtime-plan-approval/list',
                                    ],
                                },
                                {
                                    label: 'Overtime Actual',
                                    styleClass: '',
                                    routerLink: [
                                        '/mss/time/overtime/overtime-actual-approval/list',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Change Schedule',
                            styleClass: '',
                            alias: 'change-schedule-approval',
                            items: [
                                {
                                    label: 'Change Schedule',
                                    styleClass: '',
                                    routerLink: [
                                        '/mss/time/change-schedule-approval/list',
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    label: 'Reimbursement',
                    icon: 'fa-solid fa-hand-holding-dollar',
                    alias: 'reimbursement',
                    items: [
                        {
                            label: 'Donation Request',
                            styleClass: '',
                            alias: 'donation-request',
                            routerLink: [
                                '/mss/reimbursement-ess/donation-request/list',
                            ],
                        },
                        {
                            label: 'Medical Request',
                            styleClass: '',
                            alias: 'medical-request',
                            routerLink: [
                                '/mss/reimbursement-ess/medical-request/list',
                            ],
                        },
                        {
                            label: 'Business Trip',
                            alias: 'business-trip',
                            items: [
                                {
                                    label: 'Business Trip Request',
                                    styleClass: '',
                                    alias: 'business-trip-request',
                                    routerLink: [
                                        '/mss/reimbursement-ess/business-trip/business-trip-request',
                                    ],
                                },
                                {
                                    label: 'Business Trip Settlement',
                                    styleClass: '',
                                    alias: 'business-trip-settlement',
                                    routerLink: [
                                        '/mss/reimbursement-ess/business-trip/business-trip-settlement/list',
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    label: 'Payroll',
                    icon: 'fa-solid fa-wallet',
                    alias: 'payroll-mss',
                    items: [
                        {
                            label: 'Approval',
                            styleClass: '',
                            alias: 'approval',
                            routerLink: [
                                '/mss/payroll-ess/loan/loan-request-mss/index',
                            ],
                        },
                    ],
                },
                {
                    label: 'Training',
                    icon: 'fa-solid fa-users',
                    alias: 'training',
                    items: [
                        {
                            label: 'Learning Plan Approval',
                            styleClass: '',
                            alias: 'learning-plan-approval',
                            routerLink: [
                                '/mss/training/approval-learning-plan/list',
                            ],
                        },
                        {
                            label: 'Learning Review',
                            styleClass: '',
                            alias: 'learning-review',
                            routerLink: ['/mss/training/learning-preview/list'],
                        },
                    ],
                },
                {
                    label: 'Team Activity',
                    icon: 'fa-solid fa-user-tie',
                    styleClass: '',
                    alias: 'team-activity',
                    items: [
                        {
                            label: 'Employee on Presence',
                            styleClass: '',
                            alias: 'employee-on-presence',
                            routerLink: [
                                '/mss/team-activity/employee-on-presence',
                            ],
                        },
                        {
                            label: 'Employee on Late in',
                            styleClass: '',
                            alias: 'employee-on-late-in',
                            routerLink: [
                                '/mss/team-activity/employee-on-late-in',
                            ],
                        },
                        {
                            label: 'Employee on Absent',
                            styleClass: '',
                            alias: 'employee-on-absent',
                            routerLink: [
                                '/mss/team-activity/employee-on-absent',
                            ],
                        },
                        {
                            label: 'Employee on Early Out',
                            styleClass: '',
                            alias: 'employee-on-early-out',
                            routerLink: [
                                '/mss/team-activity/employee-on-early-out',
                            ],
                        },
                        {
                            label: 'Employee on Leave',
                            styleClass: '',
                            alias: 'employee-on-leave',
                            routerLink: [
                                '/mss/team-activity/employee-on-leave',
                            ],
                        },
                        {
                            label: 'Employee on Overtime',
                            styleClass: '',
                            alias: 'employee-on-time',
                            routerLink: [
                                '/mss/team-activity/employee-on-overtiime',
                            ],
                        },
                        {
                            label: 'Employee Onboarding',
                            styleClass: '',
                            alias: 'employee-onboarding',
                            routerLink: [
                                '/mss/team-activity/new-employee-today',
                            ],
                        },
                        {
                            label: 'Employee on Permit',
                            styleClass: '',
                            alias: 'employee-on-permit',
                            routerLink: [
                                '/mss/team-activity/employee-on-permit',
                            ],
                        },
                        {
                            label: 'Employee on Birthday This Month',
                            styleClass: '',
                            alias: 'employee-birthday-this-month',
                            routerLink: [
                                '/mss/team-activity/employee-birthday-this-month',
                            ],
                        },
                        {
                            label: 'Employee on Movement',
                            styleClass: '',
                            alias: 'employee-on-movement',
                            routerLink: [
                                '/mss/team-activity/employee-on-movement',
                            ],
                        },
                        {
                            label: 'Employee on Termination',
                            styleClass: '',
                            alias: 'employee-on-termination',
                            routerLink: [
                                '/mss/team-activity/employee-on-termination',
                            ],
                        },
                        {
                            label: 'Remaining Employee Contract',
                            styleClass: '',
                            alias: 'remaining-employee-contract',
                            routerLink: [
                                '/mss/team-activity/remaining-employee-contract',
                            ],
                        },
                    ],
                },
                {
                    label: 'Recruitment',
                    icon: 'fa-solid fa-users-cog',
                    styleClass: '',
                    alias: 'recruitment',
                    items: [
                        {
                            label: 'Employee Request',
                            styleClass: '',
                            alias: 'employee-request',
                            routerLink: ['/mss/recruitment/employee-request'],
                        },
                        {
                            label: 'Job',
                            styleClass: '',
                            alias: 'job',
                            routerLink: ['/mss/recruitment/job'],
                        },
                    ],
                },
                {
                    label: 'Performance',
                    icon: 'fas fa-chart-line',
                    styleClass: '',
                    alias: 'performance-ess',
                    items: [
                        {
                            label: 'Result Calibrations',
                            styleClass: '',
                            alias: 'result-calibrations',
                            routerLink: [
                                '/mss/performance-ess/result-calibrations/list',
                            ],
                        },
                        {
                            label: 'Subordinate Results',
                            styleClass: '',
                            alias: 'subordinate-results',
                            routerLink: [
                                '/mss/performance-ess/subordinate-results/list',
                            ],
                        },
                    ],
                },
            ],
        },
    ];

    constructor(
        public appMain: AppMainEssComponent,
        public router: Router,
        public auth: AuthenticationService
    ) {}

    private _setupMenus(web: string): void {
        if (web === 'ess' && this.auth.getLocalStorage(AppConstant.menuEss)) {
            this.model = this.auth.getLocalStorage(AppConstant.menuEss);
            // this.model = this.menuEss;
        }

        if (web === 'mss' && this.auth.getLocalStorage(AppConstant.menuMss)) {
            this.model = this.auth.getLocalStorage(AppConstant.menuMss);
            // this.model = this.menuMss;
        }
    }

    ngOnInit() {
        this._setupMenus((this.web = localStorage.getItem('web')));
    }

    onchangeWeb(e) {
        if (e == 'ess') {
            this.model = this.menuEss;
        } else if (e == 'mss') {
            this.model = this.menuMss;
        }
    }

    onKeydown(event: KeyboardEvent) {
        const nodeElement = <HTMLDivElement>event.target;
        if (event.code === 'Enter' || event.code === 'Space') {
            nodeElement.click();
            event.preventDefault();
        }
    }
}
